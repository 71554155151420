'use client';

import { useState, useLayoutEffect, createContext, useContext, useMemo } from 'react';
import { debounce } from 'lodash';

// utils
import {
  isGddsDesktopSelector,
  isDesktopSelector,
  isGddsLargeDesktopSelector,
  isMobileSelector,
  isGddsTabletSelector,
  isTabletSelector,
  currentGddsViewportSelector,
  currentViewportSelector,
} from 'utils/selectors/sizeSelectors';
import { useGdds } from 'utils/hooks/use-gdds';

const SizeContext = createContext<{ width: number }>({ width: 0 });

const useBrowserLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : () => {};

export function SizeProvider({
  children,
  fallbackWidth,
}: React.PropsWithChildren<{ fallbackWidth: number }>) {
  const [width, setWidth] = useState<number>(
    typeof window !== 'undefined' ? window.innerWidth : fallbackWidth,
  );

  const resize = debounce(() => {
    setWidth(window.innerWidth);
  }, 50);

  useBrowserLayoutEffect(() => {
    window.addEventListener('resize', resize);
    return () => {
      // Cancel any pending debounced execution before cleanup
      resize.cancel();
      window.removeEventListener('resize', resize);
    };
  }, []);

  const value = useMemo(() => ({ width }), [width]);

  return <SizeContext.Provider value={value}>{children}</SizeContext.Provider>;
}

export function useIsDesktop(overrides?: Partial<{ gdds: boolean }>) {
  const isGdds = useGdds();
  const sizes = useContext(SizeContext);
  return isGdds || overrides?.gdds
    ? isGddsDesktopSelector(sizes) || isGddsLargeDesktopSelector(sizes)
    : isDesktopSelector(sizes);
}

export function useIsMobile() {
  const sizes = useContext(SizeContext);
  return isMobileSelector(sizes);
}

export function useIsTablet(overrides?: Partial<{ gdds: boolean }>) {
  const isGdds = useGdds();
  const sizes = useContext(SizeContext);
  return isGdds || overrides?.gdds ? isGddsTabletSelector(sizes) : isTabletSelector(sizes);
}

export function useViewportName() {
  const isGdds = useGdds();
  const sizes = useContext(SizeContext);
  return isGdds ? currentGddsViewportSelector(sizes) : currentViewportSelector(sizes);
}
