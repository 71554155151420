import { emptyStringFallback } from '../is-empty';
import { globalsByTypeSelector } from './globalsSelectors';
import { currentLanguageSelector } from './languageSelectors';
import { translationByKeySelector } from './translationSelectors';

const navigationSelector = (state: AppState) => {
  const currentLanguage = currentLanguageSelector(state);

  if (!currentLanguage) return null;

  return state.navigation[currentLanguage];
};

const navigationByTypeSelector = <T extends 'main' | 'meta' | 'full' | 'alt-url'>(
  state: AppState,
  type: T,
) => {
  const navigation = navigationSelector(state);

  if (!navigation || !type) return null;

  return navigation[type] || null;
};

const navigationMainSelector = (state: AppState) => navigationByTypeSelector(state, 'main');

const navigationMetaSelector = (state: AppState) => navigationByTypeSelector(state, 'meta');

export const navigationMetaItemsSelector = (state: AppState) => {
  const metaNavigation = navigationMetaSelector(state);
  if (!metaNavigation) return null;

  return metaNavigation.links || null;
};

export const navigationMetaPreviewIdSelector = (state: AppState) => {
  const metaNavigation = navigationMetaSelector(state);
  if (!metaNavigation) return null;

  return emptyStringFallback(metaNavigation.previewId, null);
};

export const navigationMainItemsSelector = (state: AppState) => {
  const mainNavigation = navigationMainSelector(state);
  if (!mainNavigation) return null;

  return mainNavigation.items;
};

const campusNavigation: Omit<NavigationItem, 'originalIndex'>[] = [
  {
    label: 'Cockpit',
    url: '',
    children: [],
  },
  {
    label: 'Catalog',
    url: '',
    children: [],
  },
];

export const navigationMainCampusItemsSelector = (state: AppState) => {
  const mainNavigation = navigationMainSelector(state);
  if (!mainNavigation) return null;

  const campusGlobals = globalsByTypeSelector(state, 'campus');
  const cockpitTranslation = translationByKeySelector(state, 'campus_nav_cockpit');
  const catalogTranslation = translationByKeySelector(state, 'campus_nav_catalog');

  campusNavigation[0].label = cockpitTranslation;
  campusNavigation[0].url = campusGlobals?.campusDashboardUrl;

  campusNavigation[1].label = catalogTranslation;
  campusNavigation[1].url = campusGlobals?.campusCatalogUrl;

  return campusNavigation;
};

export const stickyHeaderShownSelector = (state: AppState) =>
  state.navigation.isStickyHeaderShown || false;
