import { useState, useRef, useEffect, useCallback, useContext } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import isEmpty from 'lodash.isempty';
import { useMutation, useQuery, useQueryClient } from 'react-query';

// components
import { SessionContext, SET_CAMPUS_COURSE_TOKEN } from 'components/Session/SessionContext';

// types
import type { CourseDetail } from 'components/Campus/types';

// utils
import { useGetEndpoint } from './use-endpoint';
import { globalsServerSelector } from '../selectors/globalsSelectors';
import { useCurrentLanguage } from './useCurrentLanguage';
import { CookieStorage } from 'components/Session/CookieStorage';
import { useTranslationFunction } from './use-translations';
import { emptyStringFallback } from '../is-empty';

export const useCampusEnv = () => {
  return useSelector((state: AppState) => state.globals.server.campus) || {};
};
// #region utilities
function getTitleByLocale(item, lang) {
  const searchLang = lang?.toLowerCase() || 'en';
  const titleKey = Object.keys(item)
    .filter((itemKey) => itemKey.startsWith(`title_${searchLang.toLowerCase().replace('_', '-')}`))
    .shift();
  if (!titleKey) return item.title;
  return item[titleKey];
}

// #region updatedhooks
export function useClassifications(enabled = true) {
  const lang = useCurrentLanguage();
  const { getEndpoint } = useGetEndpoint();
  const { data, isLoading, isError } = useQuery({
    refetchOnMount: false,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false,
    enabled,
    queryKey: ['campus-classification', { lang }],
    queryFn: () =>
      axios
        .get(getEndpoint('campusTopics', `?lang=${lang?.substring(0, 2).toLowerCase()}`))
        .then((res) => res.data),
  });

  const getClassification = useCallback(
    (id) => {
      const findClass = (topics) => {
        let itemFound = topics.find((i) => parseInt(i.id, 10) === parseInt(id, 10));
        if (itemFound) {
          return itemFound;
        }
        // deep search
        topics.every((i) => {
          if (i.topics) {
            itemFound = findClass(i.topics);
          }
          if (itemFound) {
            return false;
          }
          return true;
        });
        return itemFound;
      };
      return data?.topics && findClass(data.topics)?.topics;
    },
    [data],
  );

  return { classifications: data, error: isError, isLoading, getClassification };
}

function useCampusRequest(courseId = null) {
  const { ciamToken, employeeToken } = useCampusAuthGate(courseId);
  const lang = useCurrentLanguage();

  const fetchWithAuth = useCallback(
    (method, url) => {
      if ((!ciamToken && !employeeToken) || !lang) return Promise.reject();

      return axios({
        method,
        url,
      });
    },
    [ciamToken, employeeToken, lang],
  );

  const isLoggedIn = !!ciamToken;
  const isParticipant = !!employeeToken;

  return { fetchWithAuth, isLoggedIn, isParticipant, ciamToken, lang };
}

function useLanguages() {
  const { getEndpoint } = useGetEndpoint();
  const { data, isLoading, isError } = useQuery({
    refetchOnMount: false,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false,
    queryKey: 'campus-languages',
    queryFn: () =>
      axios
        // locale doesn't matter here since we only need this endpoint to get the ids
        .get(getEndpoint('campusRest', `/openlms/languages/de-DE`))
        .then((res) => res.data?.restLanguageItems),
  });

  return { languages: data, error: isError, isLoading };
}

export function useCatalogList({ limit, query, orderBy, orderDir, filter }) {
  const { lang, isLoggedIn } = useCampusRequest();
  const { targetGroups, competenceKnowledges, courseTypes, locations } = filter || {};
  const { languages } = useLanguages();
  const server = useSelector(globalsServerSelector);
  const { getEndpoint } = useGetEndpoint();
  const currentLang = languages?.filter(
    (language) =>
      language.code.toLowerCase().replace('-', '_') === lang?.toLowerCase().replace('-', '_'),
  );
  const currentLangId = currentLang?.[0]?.id || currentLang?.id;

  const { data, isLoading, isError } = useQuery({
    refetchOnMount: false,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false,
    enabled: isLoggedIn,
    queryKey: [
      'campus-catalog-list',
      server?.campusCatalogId,
      server?.campusClassLocations,
      {
        query,
        orderBy,
        orderDir,
        competenceKnowledges,
        targetGroups,
        courseTypes,
        currentLangId,
        locations,
        filter,
      },
    ],
    queryFn: () => {
      const normalizedLang = lang.indexOf('-') > -1 ? (lang ?? '') : `${lang}-${lang}`;
      const normalizedLangSafe = normalizedLang.replace('_', '-').toLowerCase().slice(0, 5);

      return axios
        .post(
          getEndpoint('campusSolr', ''),
          JSON.stringify({
            query,
            orderBy,
            orderDir,
            competenceKnowledges,
            targetGroups,
            courseTypes,
            lang: normalizedLangSafe,
            locations,
            filter,
            limit,
            langId: currentLangId,
          }),
          { headers: { 'Content-Type': 'application/json' } },
        )
        .then((res) => {
          const extractList = (raw) =>
            raw?.response?.docs.map((course) => ({
              ...course,
              title: getTitleByLocale(course, lang?.replace('_', '-').toLowerCase()),
            })) || [];
          const extractCount = (raw) => raw?.response?.numFound || 0;
          const extractFacets = (raw) => {
            if (!raw?.facet_counts?.facet_fields) return [];

            const fields = raw.facet_counts.facet_fields;
            const facets = {};
            Object.keys(fields)
              .filter((key) => Array.isArray(fields[key]) && fields[key].length > 0)
              .forEach((key) => {
                facets[key] = {};
                return Object.keys(fields[key]).forEach((index) => {
                  const value = fields[key][Number(index) + 1];
                  if (Number(index) % 2 === 0 && typeof value !== 'undefined') {
                    facets[key][fields[key][index]] = value;
                  }
                });
              });
            return facets;
          };

          return {
            list: extractList(res.data),
            count: extractCount(res.data),
            facets: extractFacets(res.data),
          };
        });
    },
  });

  return {
    itemList: data?.list || [],
    itemCount: data?.count,
    filterFacets: data?.facets,
    error: isError,
    isLoading,
  };
}

export function useCourseUserList({ offset, limit, orderBy, orderDir, filter }) {
  const { isLoggedIn } = useCampusRequest();
  const { getEndpoint } = useGetEndpoint();
  const { data, isLoading, isError } = useQuery({
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    enabled: isLoggedIn,
    queryKey: ['campus-usercourses', { offset, limit, orderBy, orderDir, filter }],
    queryFn: () => {
      const { personIds, competenceKnowledges, time, state } = filter || {};
      const queryData = [`limit=${limit || 10}`];
      if (offset) {
        queryData.push(`startindex=${offset}`);
      }

      // 1008226 is currently competence knowledge
      if (competenceKnowledges && Array.isArray(competenceKnowledges)) {
        queryData.push(`classifications=${competenceKnowledges.join(',')}`);
      }
      if (personIds && Array.isArray(personIds)) {
        queryData.push(`participants=${personIds.join(',')}`);
      }
      if (time) {
        queryData.push(
          `registrationDate=${time},${new Date().toISOString().slice(0, 10)},userdefined`,
        );
      }

      // booking status (all = 8,9,10,11)
      queryData.push(`courseBookingStatus=${!isEmpty(state) ? state : '8,9,10,11'}`);

      if (orderBy) {
        queryData.push(`sortby=${orderBy}`);
      }
      if (orderDir) {
        queryData.push(`sortdirection=${orderDir}`);
      }

      return axios
        .get(getEndpoint('campusCourseParticipants', `?${queryData.join('&')}`))
        .then((res) => {
          return {
            list: Array.isArray(res.data) ? res.data : res.data?.participants || [],
            count: res.data?.count || res.data?.length || 0,
          };
        });
    },
  });

  return { itemList: data?.list, itemCount: data?.count, error: isError, isLoading };
}

export function useCourseDetails(courseId) {
  const { lang } = useCampusRequest();
  const { getEndpoint } = useGetEndpoint();
  const { data, isLoading, isError, refetch } = useQuery({
    refetchOnMount: false,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false,
    queryKey: ['campus-course-details', { courseId, lang }],
    enabled: !!courseId,
    queryFn: () =>
      axios
        // locale doesn't matter here since we only need this endpoint to get the ids
        .get(getEndpoint('campusCourseDetails', `/?course_id=${courseId}&lang=${lang}`))
        .then((res) => res.data),
  });

  return { data, isLoading, error: isError, reload: refetch };
}

export function useCourseUserProgress({ userId, courseId }) {
  const { getEndpoint } = useGetEndpoint();
  const { isLoggedIn, isParticipant } = useCampusRequest(courseId);
  const lang = useCurrentLanguage();
  const { data, isLoading, isError, refetch } = useQuery({
    refetchOnMount: false,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
    retry: false,
    enabled: !!((isLoggedIn || isParticipant) && userId && courseId),
    queryKey: ['campus-course-progress', { userId, courseId }],
    queryFn: () =>
      axios
        .get(
          getEndpoint(
            'campusBookedCourseDetails',
            `?course_id=${courseId}&user_id=${userId}&lang=${lang}`,
          ),
        )
        .then((res) => {
          const progress = res.data.status === 'PASSED' ? 100 : Number(res.data.progress);
          return { progress, data: res.data };
        }),
  });

  return { progress: data?.progress, data: data?.data, isLoading, error: isError, reload: refetch };
}

export function useBookCourse() {
  const { lang } = useCampusRequest();
  const { getEndpoint } = useGetEndpoint();
  const mutation = useMutation((data: CourseDetail) => {
    return axios.post(getEndpoint('campusBooking', `?lang=${lang}`), data);
  });

  return mutation.mutate;
}

export function useCancelCourse() {
  const { ciamToken } = useCampusRequest();
  const { getEndpoint } = useGetEndpoint();

  const cancelMulti = useCallback(
    async ({ personIds, courseId }) => {
      if (!ciamToken) return Promise.resolve();

      const cancelOne = async ({ personId, cancelCourseId }) => {
        let reqPersonId = personId;
        if (!personId) {
          // workaround for /lms/courses/${courseId}/status because endpoint does not work
          const user = await axios.get(getEndpoint('campusRest', `/lms/users/@self`));
          reqPersonId = user.data.id;
        }
        return axios.patch(
          getEndpoint(
            'campusBookingState',
            `?course_id=${cancelCourseId}&user_id=${reqPersonId}&action=CANCELLED`,
          ),
        );
      };

      const promises = personIds.map((personId) =>
        cancelOne({ personId, cancelCourseId: courseId }),
      );
      return await Promise.allSettled(promises).then((promiseList) =>
        promiseList.map((promise, index) => ({
          status: promise.status,
          personId: personIds[index],
        })),
      );
    },
    [ciamToken, getEndpoint],
  );

  return {
    cancelMulti,
  };
}

export function useCourseParticipants(courseId?: number) {
  const { isLoggedIn, lang } = useCampusRequest();
  const { getEndpoint } = useGetEndpoint();
  const { data, isLoading, isError } = useQuery({
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    enabled: isLoggedIn,
    queryKey: ['campus-courseparticipants', { courseId, lang }],
    queryFn: () => {
      let courseParam = '';
      if (courseId) courseParam = `&course_id=${courseId}`;
      return axios
        .get(getEndpoint('campusCourseParticipants', `?lang=${lang}${courseParam}`))
        .then((res) => ({
          list: Array.isArray(res.data) ? res.data : res.data?.participants || [],
          count: res.data?.count || res.data?.length || 0,
        }));
    },
  });

  return { participants: data?.list, participantCount: data?.count, error: isError, isLoading };
}

function useFilterBase(setFilters, onResetCallback?: () => void) {
  const [filterReset, setFilterReset] = useState(false);
  const { classifications, getClassification } = useClassifications();

  const sortItems = ({ text: aText }, { text: bText }) => {
    if (aText.toLowerCase() < bText.toLowerCase()) return -1;
    if (aText.toLowerCase() > bText.toLowerCase()) return 1;
    return 0;
  };

  const setFilter = (filtername) => (value) => {
    setFilters((prev) => ({ ...prev, [filtername]: value }));
  };

  const setFilterResetCustom = (resetValue) => {
    setFilters(() => {
      return {};
    });
    setFilterReset(resetValue);
    if (typeof onResetCallback === 'function') onResetCallback();
  };

  return {
    filterReset,
    setFilterReset: setFilterResetCustom,
    classifications,
    sortItems,
    getClassification,
    setFilter,
  };
}
type IOption = {
  id: string;
  value: string;
  text: string;
};

type FilterItem = {
  title: string;
  options: IOption[];
  setFilter: (string) => void;
  defaultSelectedValues?: string[];
  singleValue?: boolean;
};

export function useFilterCatalog(setFilters, onResetCallback, defaultSelectedValues) {
  const {
    CAMPUS_CLASS_TARGETGROUPS,
    CAMPUS_CLASS_COMP_KNOWLEDGE,
    CAMPUS_CLASS_LEARNINGFORMS,
    CAMPUS_FILTER_TYPE_BLACKLIST,
  } = useCampusEnv();
  const { filterReset, setFilter, setFilterReset, classifications, sortItems, getClassification } =
    useFilterBase(setFilters, onResetCallback);
  const [filterItems, setFilterItems] = useState<FilterItem[]>([]);
  const server = useSelector(globalsServerSelector);
  const targetGroups = getClassification(CAMPUS_CLASS_TARGETGROUPS);
  const competenceKnowledges = getClassification(CAMPUS_CLASS_COMP_KNOWLEDGE);
  const locations = getClassification(server?.campusClassLocations?.split('/').pop());
  const courseTypes = getClassification(CAMPUS_CLASS_LEARNINGFORMS);
  const { languages } = useLanguages();

  useEffect(() => {
    setFilterItems([
      {
        title: 'campus_targetgroup',
        options: targetGroups?.map((item) => ({
          id: item.id,
          value: item.id,
          text: item.name,
        })),
        setFilter: setFilter('targetGroups'),
        defaultSelectedValues: defaultSelectedValues?.targetGroups?.map((e) => parseInt(e)),
      },
      {
        title: 'campus_competences_knowledge',
        options: competenceKnowledges?.map((item) => ({
          id: item.id,
          value: item.id,
          text: item.name,
        })),
        setFilter: setFilter('competenceKnowledges'),
        defaultSelectedValues: defaultSelectedValues?.competenceKnowledges?.map((e) => parseInt(e)),
      },
      {
        title: 'campus_coursetype',
        options: courseTypes
          ?.map((item) => ({
            id: item.id,
            value: item.id,
            text: item.name,
          }))
          .filter((item) => CAMPUS_FILTER_TYPE_BLACKLIST.indexOf(item.id) === -1),
        setFilter: setFilter('courseTypes'),
        defaultSelectedValues: defaultSelectedValues?.courseTypes?.map((e) => parseInt(e)),
      },
      // https://geberit.visualstudio.com/Web%20Platform/_workitems/edit/339527
      // {
      //   title: 'campus_traininglocations',
      //   options: locations?.map((item) => ({
      //     id: item.id,
      //     value: item.id,
      //     text: item.name,
      //   })),
      //   setFilter: setFilter('locations'),
      //   defaultSelectedValues: defaultSelectedValues?.locations?.map((e) => parseInt(e)),
      // },
    ]);
  }, [classifications, languages, locations]);

  return { filterReset, setFilterReset, sortItems, filterItems };
}

export function useFilterDashboard(setFilters) {
  const { CAMPUS_CLASS_COMP_KNOWLEDGE } = useCampusEnv();
  const { filterReset, setFilter, setFilterReset, sortItems, getClassification } =
    useFilterBase(setFilters);
  const [filterItems, setFilterItems] = useState<FilterItem[]>([]);
  const translate = useTranslationFunction();
  const competenceKnowledges = getClassification(CAMPUS_CLASS_COMP_KNOWLEDGE);
  const { participants: persons, isLoading: personsLoading } = useCourseParticipants();

  useEffect(() => {
    if (!isEmpty(competenceKnowledges) && !personsLoading) {
      setFilterItems([
        {
          title: 'campus_employees',
          options: persons?.reduce((acc, item) => {
            const alreadyIncludedItem = acc.find(
              (i) => i.text === `${item.firstname} ${item.lastname}`,
            );
            if (alreadyIncludedItem) {
              alreadyIncludedItem.value = `${alreadyIncludedItem.value},${item.id}`;
            } else {
              acc.push({
                id: item.id,
                value: item.id,
                text: `${item.firstname} ${item.lastname}`,
              });
            }

            return acc;
          }, []),
          setFilter: setFilter('personIds'),
        },
        {
          title: 'campus_competences_knowledge',
          options: competenceKnowledges?.map((item) => ({
            id: item.id,
            value: item.id,
            text: item.name,
          })),
          setFilter: setFilter('competenceKnowledges'),
        },
        {
          title: 'campus_time',
          options: [
            {
              id: 'last_3',
              value: (() => {
                const dateCompare = new Date();
                dateCompare.setMonth(dateCompare.getMonth() - 3);
                return dateCompare.toISOString().slice(0, 10);
              })(),
              text: translate('campus_filter_3months') || 'Last 3 Months',
            },
          ],
          singleValue: true,
          setFilter: setFilter('time'),
        },
        {
          title: 'campus_state',
          options: [
            {
              id: '',
              value: '8,9',
              text: translate('campus_filter_inprocess') || 'In process',
            },
            {
              id: 'completed',
              value: '10,11',
              text: translate('campus_filter_completed') || 'Completed',
            },
          ],
          setFilter: setFilter('state'),
        },
      ]);
    }
  }, [competenceKnowledges, personsLoading]);

  return { filterReset, setFilterReset, sortItems, filterItems };
}

export function useLocationDetails(locationId) {
  const { fetchWithAuth, isLoggedIn, lang } = useCampusRequest();
  const { getEndpoint } = useGetEndpoint();
  const { data, isLoading, isError } = useQuery({
    refetchOnMount: false,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false,
    enabled: isLoggedIn && !!locationId,
    queryKey: ['campus-location-details', { locationId, lang }],
    queryFn: () =>
      fetchWithAuth('get', getEndpoint('campusRest', `/lms/locations/${locationId}`)).then(
        (res) => {
          const description = res.data?.description?.split(',');

          return {
            id: res.data.id,
            name: res.data.name,
            address: `${res.data.street || ''}${
              res.data.street && (res.data.postalCode || res.data.city) ? '<br />' : ''
            }${res.data.postalCode || ''} ${res.data.city || ''}`.trim(),
            availability: (res.data.building || '').trim(),
            postalCode: (res.data.postalCode || '').trim(),
            city: (res.data.city || '').trim(),
            street: (res.data.street || '').trim(),
            mail: (description && description.length > 0 && description[0].trim()) || '',
            phone: (description && description.length > 1 && description[1].trim()) || '',
            buttonTarget: res.data.externalLink || '',
          };
        },
      ),
  });

  return { data, error: isError, isLoading };
}

export function useUserAttributes(habit) {
  const { isLoggedIn, lang } = useCampusRequest();
  const { getEndpoint } = useGetEndpoint();
  const { data } = useQuery({
    refetchOnMount: false,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false,
    enabled: isLoggedIn,
    queryKey: ['campus-user-attr', { habit, lang }],
    queryFn: () =>
      axios
        .get(getEndpoint('campusAttributes', `/?attribute=${habit}&lang=${lang}`))
        .then((res) => res.data?.entries?.map((entry) => ({ value: entry.id, text: entry.value }))),
  });

  return [data];
}

export function usePublicCatalogList() {
  const { lang } = useCampusRequest();
  const { getEndpoint } = useGetEndpoint();
  const { data, isLoading, isError } = useQuery({
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    queryKey: ['campus-public-courses', { lang }],
    queryFn: () =>
      axios.get(getEndpoint('campusCourses', `?start=0&count=4&lang=${lang}`)).then((res) => {
        const extractList = (raw) =>
          raw?.response?.docs?.map((item) => ({
            ...item,
            title: getTitleByLocale(item, lang),
          })) || [];

        return {
          list: extractList(res.data),
          count: res.data?.response?.numFound || 0,
        };
      }),
  });

  return { list: data?.list, count: data?.count, isLoading, isError };
}

export function useCampusNotifyCode() {
  const { getEndpoint } = useGetEndpoint();
  const mutation = useMutation<unknown, unknown, { courseId: number; userId: number }>(
    ({ courseId, userId }) =>
      axios.get(
        getEndpoint('campusResendBookingMail', `?course_id=${courseId}&participant_id=${userId}`),
      ),
  );

  return mutation.mutate;
}

export function useCampusTypes(topics) {
  const { CAMPUS_META } = useCampusEnv();
  const [result, setResult] = useState(['webinar', 'webinar']);

  useEffect(() => {
    if (topics) {
      if (topics.some((el) => el.id === CAMPUS_META.WEBINAR)) {
        setResult(['webinar', 'webinar']);
      }

      if (topics.some((el) => el.id === CAMPUS_META.WEBCAST)) {
        setResult(['webcast', 'webcast']);
      }

      if (topics.some((el) => el.id === CAMPUS_META.ELEARNING)) {
        setResult(['elearning', 'elearning']);
      }

      if (topics.some((el) => el.id === CAMPUS_META.EVENT)) {
        setResult(['event', 'event']);
      }

      if (topics.some((el) => el.id === CAMPUS_META.SEMINAR)) {
        setResult(['event', 'seminar']);
      }
    }
  }, [topics]);

  return result;
}

export function useCourseStatus(courseId, personId) {
  const { fetchWithAuth, isLoggedIn } = useCampusRequest();
  const { getEndpoint } = useGetEndpoint();
  const { data, isLoading, isError } = useQuery({
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
    enabled: Boolean(isLoggedIn && courseId && personId),
    queryKey: ['campus-course-status', { courseId, personId }],
    queryFn: async () =>
      fetchWithAuth(
        'get',
        getEndpoint('campusRest', `/lms/courses/${courseId}/participants/${personId}/status`),
      ).then((res) => res.data),
  });

  return [data, isLoading, isError];
}

// #endregion

async function pause(delay: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay * 1000);
  });
}

async function fetchQuery(query: () => Promise<any>, previousMedias: any = [], count = 0) {
  const newMedias = await query();
  const isWebScorm = newMedias?.some((item) => item?.media?.contentType === 'WBT_SCORM');
  if (isWebScorm && count < 5 && JSON.stringify(newMedias) === JSON.stringify(previousMedias)) {
    await pause(1);
    return fetchQuery(query, newMedias, count + 1);
  } else {
    return newMedias;
  }
}

// #region hooks
export function useCourseMedia(courseId, language) {
  const { ciamToken, employeeToken } = useCampusAuthGate(courseId);
  const queryClient = useQueryClient();
  const { getEndpoint } = useGetEndpoint();

  const { data, isLoading, isError } = useQuery({
    refetchOnMount: false,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
    enabled: Boolean(emptyStringFallback(ciamToken, employeeToken) && language),
    queryKey: ['campus-coursemedia', { courseId, language, ciamToken, employeeToken }],
    queryFn: ({ queryKey }) => {
      const isFirefox = navigator.userAgent.indexOf('Firefox') !== -1;
      const query = () =>
        axios
          .get(
            getEndpoint(
              'campusRest',
              `/lms/v2/courses/${courseId}/media/?language=${language}&courseId=${courseId}`,
            ),
          )
          .then((res) => res?.data?.medias);

      if (isFirefox) {
        return query();
      } else {
        const previousMedias = queryClient.getQueryData(queryKey);
        return fetchQuery(query, previousMedias);
      }
    },
  });

  return { items: data, isLoading, isError };
}

function useCourseLibrary(courseId, language) {
  const { getEndpoint } = useGetEndpoint();
  const { ciamToken: employeeToken } = useCampusAuthGate();
  const [items, setItems] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const mounted = useRef(false);

  useEffect(() => {
    if (!employeeToken || !language || !courseId) {
      setIsLoading(false);
      return () => {};
    }

    mounted.current = true;
    setIsLoading(true);
    axios
      .get(getEndpoint('campusRest', `/lms/courses/${courseId}/library?allContent=true`))
      .then((res) => {
        if (mounted.current) {
          setItems(res.data.medias);
        }
      })
      .catch((err) => {
        if (mounted.current) {
          setItems(null);
          setError(err);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
    return () => {
      mounted.current = false;
    };
  }, [courseId, language, employeeToken]);

  return [items, isLoading, error];
}

export function useCourseCertificate(courseId, participantId, hasCertificate) {
  const { getEndpoint } = useGetEndpoint();
  const { ciamToken, employeeToken } = useCampusAuthGate(courseId);

  const getCertificate = useCallback(() => {
    if (
      courseId &&
      participantId &&
      emptyStringFallback(ciamToken, employeeToken) &&
      hasCertificate
    ) {
      (async () => {
        // check wether we are an employee or supervisor
        const currentUser = await axios.get(
          getEndpoint('campusRest', `/lms/users/@self?courseId=${courseId}`),
        );
        const isSelf = Number(currentUser?.data?.id) === Number(participantId);

        const endpoint = getEndpoint(
          'campusRest',
          isSelf
            ? `/lms/courses/${courseId}/certificate?provideAsDownload=true&courseId=${courseId}`
            : `/lms/users/${participantId}/courses/${courseId}/certificate?provideAsDownload=true`,
        );

        const response = await axios.get(endpoint, {
          responseType: 'arraybuffer',
        });
        if (response.status === 200) {
          const blob = new Blob([response.data], { type: response.headers['content-type'] });
          const url = window.URL.createObjectURL(blob);

          Object.assign(document.createElement('a'), {
            target: '_blank',
            href: url,
            download: 'certificate.pdf',
          }).click();
        }
      })();
    }
  }, [courseId, participantId, ciamToken, employeeToken, hasCertificate]);

  return getCertificate;
}

export function useCourseDownload(courseId, language): [string | null, boolean | null] {
  const { CAMPUS_BASE_URL } = useCampusEnv();
  const [subitems, loading] = useCourseLibrary(courseId, language);
  const [link, setLink] = useState<string | null>(null);

  useEffect(() => {
    if (Array.isArray(subitems) && subitems.length > 0) {
      const firstMediaWithDownload = subitems.find((item) => item.media?.mediaFileLink);
      if (firstMediaWithDownload) {
        setLink(`${CAMPUS_BASE_URL}/admin${firstMediaWithDownload.media.mediaFileLink}`);
      }
    }
  }, [subitems]);

  return [link, loading];
}

export function useCourseSubitemDescription(courseId, subitemId, language) {
  const [subitemData, setSubitemData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { getEndpoint } = useGetEndpoint();

  const { ciamToken, employeeToken } = useCampusAuthGate(courseId);

  useEffect(() => {
    let mounted = true;
    setIsLoading(true);

    (async () => {
      if (!ciamToken && !employeeToken) return;

      try {
        const res = await axios.get(
          getEndpoint(
            'campusRest',
            `/lms/courses/${courseId}/media/${subitemId}/?language=${language}&courseId=${courseId}`,
          ),
        );
        if (mounted) {
          setSubitemData(res.data || null);
          setError(null);
          setIsLoading(false);
        }
      } catch (e) {
        if (mounted) {
          setSubitemData(null);
          setError(e);
          setIsLoading(false);
        }
      }
    })();

    return () => {
      mounted = false;
    };
  }, [ciamToken, employeeToken]);

  return [subitemData, isLoading, error];
}

export function useParticipantLogin(courseId, mutationEvents = {}) {
  const {
    state: {
      campus: { courses },
    },
    dispatch,
  } = useContext(SessionContext);
  const { getEndpoint } = useGetEndpoint();
  const { refreshToken, token: employeeToken, username } = courses[courseId] || {};

  useEffect(() => {
    const storageUsername = window.sessionStorage.getItem(`employeeUsername-${courseId}`);
    const storageToken = window.sessionStorage.getItem(`employeeAccessToken-${courseId}`);
    const storageRefresh = window.sessionStorage.getItem(`employeeRefreshToken-${courseId}`);
    dispatch({
      type: SET_CAMPUS_COURSE_TOKEN,
      courseId,
      data: {
        username: storageUsername,
        token: storageToken,
        refreshToken: storageRefresh,
      },
    });
  }, [courseId]);

  const { mutate, isLoading, isError } = useMutation(
    (username) =>
      axios
        .get(getEndpoint('campusToken', `/?username=${username}`), {
          headers: {
            Accept: 'application/json',
          },
        })
        .then((res) => {
          window.sessionStorage.setItem(`employeeUsername-${courseId}`, `${username}`);
          window.sessionStorage.setItem(`employeeAccessToken-${courseId}`, res.data.accessToken);
          window.sessionStorage.setItem(`employeeRefreshToken-${courseId}`, res.data.refreshToken);
          window.sessionStorage.setItem(
            `employeeTokenExpiry-${courseId}`,
            `${Math.floor(Date.now() / 1000) + res.data.expiresIn - 60}`,
          );
          dispatch({
            type: SET_CAMPUS_COURSE_TOKEN,
            courseId,
            data: {
              username,
              token: res.data.accessToken,
            },
          });
        })
        .catch((err) => {
          window.sessionStorage.removeItem(`employeeUsername-${courseId}`);
          window.sessionStorage.removeItem(`employeeAccessToken-${courseId}`);
          window.sessionStorage.removeItem(`employeeRefreshToken-${courseId}`);
          window.sessionStorage.removeItem(`employeeTokenExpiry-${courseId}`);
          dispatch({
            type: SET_CAMPUS_COURSE_TOKEN,
            courseId,
            data: null,
          });
          throw new Error(err);
        }),
    {
      ...mutationEvents,
    },
  );

  const { data, refetch } = useQuery({
    refetchInterval: 5 * 60 * 1000,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
    queryKey: `campus-employee-token-${courseId}`,
    enabled: !!employeeToken && !!refreshToken,
    queryFn: () => {
      const refresh = window.sessionStorage.getItem(`employeeRefreshToken-${courseId}`);
      return axios
        .get(getEndpoint('campusRefresh', `/?refresh_token=${refresh}`))
        .then((res) => {
          window.sessionStorage.setItem(`employeeAccessToken-${courseId}`, res.data?.accessToken);
          window.sessionStorage.setItem(
            `employeeTokenExpiry-${courseId}`,
            `${Math.floor(Date.now() / 1000) + res.data.expiresIn - 60}`,
          );
          dispatch({
            type: SET_CAMPUS_COURSE_TOKEN,
            courseId,
            data: {
              token: res.data.accessToken,
            },
          });

          return res.data?.accessToken;
        })
        .catch(() => {
          window.sessionStorage.removeItem(`employeeAccessUsername-${courseId}`);
          window.sessionStorage.removeItem(`employeeAccessToken-${courseId}`);
          window.sessionStorage.removeItem(`employeeRefreshToken-${courseId}`);
          window.sessionStorage.removeItem(`employeeTokenExpiry-${courseId}`);

          dispatch({
            type: SET_CAMPUS_COURSE_TOKEN,
            courseId,
            data: null,
          });
        });
    },
  });

  useEffect(() => {
    if (!window.sessionStorage.getItem(`employeeTokenExpiry-${courseId}`)) return;

    const now = Math.floor(Date.now() / 1000);
    if (
      now >
      parseInt(window.sessionStorage.getItem(`employeeTokenExpiry-${courseId}`) || '0', 10) - 300
    ) {
      refetch();
    }
  });

  return {
    employeeToken: employeeToken || data,
    accessLogin: mutate,
    isLoading,
    isError,
    username,
  };
}

export function useEmployeeToken(courseId: number) {
  if (typeof window === 'undefined') {
    return {};
  }

  return {
    refreshToken: CookieStorage.getItem(`campus_refresh_token_${courseId}`),
    token: CookieStorage.getItem(`campus_access_token_${courseId}`),
  };
}

export function useCampusAuthGate(courseId?) {
  const {
    state: {
      campus: { token: ciamToken, refreshToken, loading: isLoading },
    },
  } = useContext(SessionContext);
  const { token: employeeToken, refreshToken: employeeRefreshToken } = useEmployeeToken(courseId);

  return {
    ciamToken: emptyStringFallback(ciamToken, null),
    employeeToken: emptyStringFallback(employeeToken, null),
    refreshToken: emptyStringFallback(refreshToken, employeeRefreshToken),
    isLoading,
  };
}

export function useCampusMediaState(courseId) {
  const { getEndpoint } = useGetEndpoint();
  const { mutate } = useMutation<unknown, unknown, { mediaId: number; accessToken: string }>(
    ({ mediaId }) =>
      fetch(getEndpoint('campusRest', `/learningstate?courseId=${courseId}&mediaId=${mediaId}`), {
        method: 'put',
        // we use STARTED instead of PASSED because of imc logic for videos
        body: 'STARTED',
      }),
  );

  return {
    setCompleted: mutate,
  };
}

export function useScormAuth(courseId) {
  const { SCORM_URI } = useCampusEnv();
  const { lang } = useCampusRequest();
  const { ciamToken, employeeToken, refreshToken } = useCampusAuthGate(courseId);
  const { getEndpoint } = useGetEndpoint();

  const openScorm = useCallback(
    async (mediaId) => {
      const scoId = await axios
        .get(
          getEndpoint(
            'campusRest',
            `/lms/scorm/${mediaId}/structure?lang=${lang}&courseId=${courseId}`,
          ),
        )
        .then((res) => res?.data?.scormWbtStructure?.[0].id);

      if (courseId && mediaId && scoId) {
        const postForm = document.querySelector('#scorm_postform');
        if (postForm) postForm.remove();

        const formElement = document.createElement('form');
        formElement.id = 'scorm_postform';
        formElement.target = '_blank';
        formElement.method = 'POST';
        formElement.action = `${SCORM_URI}?wbtId=${mediaId}&parentCourseId=-1&mediaId=${mediaId}&scoId=${scoId}&courseId=${courseId}`;

        const userElement = document.createElement('input');
        userElement.name = 'oauth_token';
        userElement.value = btoa(
          `{"access_token":"${emptyStringFallback(
            ciamToken,
            employeeToken,
          )}","refresh_token":"${refreshToken}","token_type":"Bearer","expires_in":3600}`,
        );
        formElement.appendChild(userElement);

        const hiddenIframe = document.createElement('iframe');
        hiddenIframe.id = 'scorm_iframe';
        hiddenIframe.name = 'scorm_iframe';
        hiddenIframe.style.display = 'none';
        formElement.appendChild(hiddenIframe);

        formElement.style.display = 'none';
        document.body.appendChild(formElement);
        formElement.submit();
      }
    },
    [ciamToken, employeeToken, courseId],
  );

  return { openScorm };
}

export function useSelfUser(courseId) {
  const { ciamToken, employeeToken } = useCampusAuthGate(courseId);
  const { getEndpoint } = useGetEndpoint();
  const token = emptyStringFallback(ciamToken, employeeToken);

  const { data: selfData, isLoading } = useQuery({
    refetchOnMount: false,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false,
    enabled: Boolean(token),
    queryKey: ['campus-self', token],
    queryFn: () =>
      axios
        .get(getEndpoint('campusRest', `/lms/users/@self/?courseId=${courseId}`))
        .then((res) => res.data),
  });

  return { selfData, isLoading };
}
// #endregion
